.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.loading-container img {
    width: 100px;
}
.full-page-loading{


}

.full-page-loading p{ 
color: white !important;

}
.loading-modal-container{
    background: none !important;  
        width: fit-content !important;
        height: fit-content !important;
        margin: auto !important;
    
    

}
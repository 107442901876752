.edit-profile-container{
    background-color: white !important;
    display: flex;
justify-content: center;

}
.edit-profile-container input{
    width: auto;
    display: inline-block;
}

.disabled-input-fields input{
    background-color: white !important;
    border: none;

}
.input-editable input{
    border-bottom: 1px solid gainsboro;
}
.edit-profile-header{
border-bottom: none !important;
}
.user-name-edit{
    color: var(--elillalilla);
    align-self: center;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
    padding-bottom: 3px;
    font-size: 3em;
    margin-bottom: 20px;

}

.edit-profile-image{
    width: 100px;
    height: 100px;
    margin-left: 100px;
    border-radius: 8px;
}
.edit-btn{
   align-self: end;
   cursor: pointer;
}
.btn-container{
    display: flex;
    justify-content: space-evenly
}
.btn-container .edit-btn{
margin: 0 2px;
}

/*new*/

.profile-image-container{
    width: fit-content;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.upload-btn-style{
    max-width: 200px;
    align-self: flex-start;
    margin-top: 5px !important;

}
@media screen and (max-width: 500px) {
    .profile-image-container {
      width: 100% !important;
    }
    .upload-btn-style{
        align-self: center;
    }
  }


.edit-profile-btn-container{

margin-top: 3rem;
}


.checkbox-style{
    margin-top: 20px;
}

.worker-type-selector{
padding: 0 !important;
}

.form-data-input{
    margin-bottom: 2rem !important;
}
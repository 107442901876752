
.message-list-container {
    padding: 10px;
    padding-bottom: 70px;
    position: relative;

  }
/***toolbar*/
.toolbar {
    display: flex;
    align-items: center;
  
    background-color: white;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;
  
    position: sticky;
    top: 0px;
    z-index: 1;
    padding: 10px 0;
height: 60px;
  }
  
  @supports (backdrop-filter: blur(20px)) {
    .toolbar {
      border: none;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(20px);
    }
  }
  
  .toolbar-title {
    
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color:#212529;
    text-decoration: none; /* no underline */

  }
  .toolbar-title:hover {
    color:#212529;
    cursor: pointer;
  }
  
  .left-items, .right-items {
    flex: 1;
    padding: 10px;
    display: flex;
  }
  
  .right-items {
    flex-direction: row-reverse;
  }
  
  .left-items .toolbar-button {
    margin-right: 20px;
  }
  
  .right-items .toolbar-button {
    margin-left: 20px;
  }
  
  .left-items .toolbar-button:last-child,
  .right-items .toolbar-button:last-child {
    margin: 0;
  }


  
.ytv-btn-container{
    position: absolute;
    z-index: 5;
    left: 50px;
    bottom: 40px;
    padding-right: 0px ;
    border: 2px solid #ff695e;
    color: #ff695e;
    font-size: 20px;
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    flex-direction: row;
    cursor: pointer;
    font-weight: 600;
}
.ytv-btn-container .ytv-icon{
    margin: 0px!important;
    margin-left: 5px !important;
    }

.embeded-yt-container{
    position: absolute;
    z-index: 998;
    width: 100%;
}
.modal {
    height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
}


@media screen  and (max-width: 768px) {
    .ytv-btn-container{
        font-size: 15px;
        padding: 10px 15px;
        bottom: 500px;
        left: auto;
        right: 100px;
    }
}
@media screen  and (max-width: 568px) {
    .ytv-btn-container{
        font-size: 15px;
        padding: 5px 5px;
        bottom: 70px;
        left: 50px;
        right: auto;

    }
    .ytv-btn-container .ytv-icon{
        font-size: 15px !important;

        }
    
    }
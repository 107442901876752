.right-burger-menu-container{
    z-index: 10000000;
}

.burger-close-btn{
    position: absolute;
    top: 12px;
    left: 10px;
    cursor: pointer;
}
.burger-menu-header{
    width: 100% ;
    height: 50px ;

}

.burger-menu-item{
    display: flex !important;
    flex-direction: row !important;
    justify-content:start;
    align-items: center;

    font-size: 1.4rem!important;
    
    padding-top: 10px ;
    padding-bottom: 10px ;

    font-weight: 400;
    display: block;
    color: #1b1c1d;
    border-radius: 0!important;
    border-bottom: 0.6px solid #e0d4d4;


}
.burger-menu-item i {
   
margin-right: 15px; 

}
.burger-menu-icon{
    margin: 0 !important;

}

i.user.icon.burger-menu-icon{
    margin: 0px !important;

}
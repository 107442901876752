.mobile-bottom-nav {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  margin-bottom: 0px !important;
}
@media screen and (min-width: 500px) {
  .mobile-bottom-nav {
    display: none;
  }
}

.mobile-bottom-nav i.icon{
font-size: 1.5em !important;
}
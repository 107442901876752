.cutstom-choice-botton {
    color: var(--elillaLilla) !important;
    /* border-color: red !important; */
    /* background-color: red !important; */
    box-shadow: 0 0 0 1px var(--elillaLilla) inset !important;
}

/*
.cutstom-choice-botton :hover{
background: var(--elillaLilla) !important;
color: white !important;
box-shadow: unset;
}
button.ui.violet.medium.basic.button.cutstom-choice-botton:hover{
    background-color: var(--elillaLilla) !important;
    color: white !important;

}
button.ui.violet.medium.basic.button.cutstom-choice-botton{
    color: var(--elillaLilla) !important;
    box-shadow: 0 0 0 1px var(--elillaLilla)  inset !important;

}

button.ui.violet.medium.active.basic.button.cutstom-choice-botton{
    background-color: var(--elillaLilla) !important;
    color: white !important;
}*/

.afterlogin-choice-item {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1.5px dotted var(--elillaLilla);
    border-radius: 26px;
    padding: 31px 50px;
    cursor: pointer;
    font-weight: 600;
    width: 260px;
}

.afterlogin-choice-item:hover {
    background-color: var(--elillaLilla);
    color: white;


}
.modal-search-icon-style{
    font-size: 1.8em;
    color:  var(--elillaLilla);
    
}

.afterlogin-choice-item i{
    margin-bottom: 14px !important;

}
.afterlogin-choice-item:hover .modal-search-icon-style{
    color: white;
    }

.afterlogin-choice-item img {
    width: 40px;
    margin-bottom: 14px;
}

.afterlogin-choice-item h3 {
    font-weight: 600;
}

.afterlogin-choice-item-container {
    display: flex;
    justify-content: space-evenly;

    padding-bottom: 50px;
    margin-top: 60px;
}

.modal-close-icon-style {
    color: #5a5a5a;
    font-size: 1.2em !important;
    cursor: pointer;
}

.afterlogin-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 569px) {
    .afterlogin-choice-item-container {
        flex-direction: column;
        align-items: center;
        margin-top: 35px;
    }

    .afterlogin-choice-item:first-child {
        margin-bottom: 20px;
    }

    .afterlogin-choice-item {
        padding: 10px 23px;
        width: 200px;
    }
}

.afterlogin-logo-image{
    width: 189px;
    margin: auto;
    margin-bottom: 55px;
    margin-top: 34px;
}
.welcome-text-after-login{
    font-size: 1.1rem;
    margin-bottom: 60px;
}
.afterlogin-back-arrow{
    margin: 0px !important;
}
.afterlogin-success-icon {
    color: var(--elillaGreen) !important;
  }
  
.afterlogin-modal-header-text{
    margin: 0px;
}
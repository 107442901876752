.cookie-warning-container{
    position: fixed;
    bottom: 0;
    height: 120px;
    width: 100%;
    background: var(--elillaLilla);
    z-index: 1000;
    margin: 0;
    max-width: unset;
    left: 0;
    right: 0;
}
.cookie-warning-button-container{
    display: flex;
    align-items: start;   
    margin-top: 5px;
}
.cookie-warning-content-container{
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    padding: 15px;

}
.cookie-warning-text-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 50rem;
}

.cookie-warning-text-container .cookie-warning-body{
    max-width: 90%;
    color: white;
}
.cookie-warning-body a{
cursor: pointer;
text-decoration: underline;
color: white;
}

.off {
    animation: slideOut .7s ease-in-out both;
  }
  @keyframes slideOut {
    0%   { bottom: 0; }
    100% { bottom: -100%; }
  }

  @media (max-width: 768px) {
  
    .cookie-warning-content-container{
        flex-direction: column;
    
    
    }
    .cookie-warning-container{
        height: auto;
 
     }
     .cookie-warning-button-container{
        margin-top: 10px
     }
    }

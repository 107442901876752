.btn:hover {
  cursor: pointer;
}
.signup-container {
  width: 100%;
  height: 50vw;
  padding: 50px 0px 0px 0px;
}

.hidden {
  display: none;
}
.red-border{
  border: 1px solid red;
}
.styles_modal__gNwvD{
  max-width: 440px !important ;
}

.form-input-error input{
  border: 1px solid red !important;
}
.accept-policy-box{
  display: flex;
}
.accept-policy-box  p {
margin-left: 5px;
}

.accept-policy-box  a {
  text-decoration: underline;
}
.sign-up-login-btn-link span{
  text-decoration: underline;

}
/* Global Settings */
:root {
  --link: #337ab7;
  --blue: rgb(109, 193, 236);
  --orange: #e67107;
  --green: #6da262;
  --lightgreen: #d0ecd7;
  --greybrown: #897571;
  --lightgrey: #d9d9d9;
  --mediumgrey: #545454;
  --darkgrey: #333333;
  --secondary-font: 'Raleway', sans-serif;
}

/*imports for new design*/
@import './global.css';

/* Import fonts 
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');


*/




html {
  font-size: 14px;
  /* Avoid content from jumping when modal is opened/closed */
  scroll-behavior: smooth;

}

body {
  margin: 0;
  padding: 0;
  background: white;
  font-family: var(--primary-font);
  color: var(--eDarkGrey);
}

 /*html, body { overflow-x: hidden; }*/

.toast-content-container {
  display: flex;
  align-items: center;
}
.toast-content-container h1 {
  font-size: 1rem;
  margin: 0;
}
.toast-content-container p {
  font-size: 0.9rem;
  margin: 0;
  color: var(--elillaGrey) !important;
}

.Toastify__toast {
  width: 327px !important;
  background-color: #fbf7ff !important; /**/
  color: black !important;
}
.Toastify__toast-container {
  top: 6em !important;
}
.Toastify__toast--default {
}


.Toastify__toast--info .toast-left-icon {
  color: var(--elillaLilla) !important;
}

.Toastify__toast--info .toast-left-border {
  background-color: var(--elillaLilla) !important;
}

.Toastify__toast--info .Toastify__progress-bar {
  background-color: var(--elillaLilla) !important;
}



.Toastify__toast--success .toast-left-icon {
  color: var(--elillaGreen) !important;
}
.Toastify__toast--success .toast-left-border {
  background-color: var(--elillaGreen) !important;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: var(--elillaGreen) !important;
}

.Toastify__close-button {
  color: var(--elillaGrey) !important;

  top: 0;
  bottom: 0;
  right: 13px;
  position: absolute;
  margin: auto;
  outline: none !important; 

}


.Toastify__toast--warning .toast-left-icon {
  color: var(--elillaOrange) !important;
}

.Toastify__toast--warning .toast-left-border {
  background-color: var(--elillaOrange) !important;
}

.Toastify__toast--warning .Toastify__progress-bar {
  background-color: var(--elillaOrange) !important;
}

.Toastify__toast--error .toast-left-icon {
  color: var(--elillaRed) !important;
}

.Toastify__toast--error .toast-left-border {
  background-color: var(--elillaRed) !important;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: var(--elillaRed) !important;
}

.toast-left-border {
  width: 2px;
  height: 50px;
  margin-right: 05px;
  border-radius: 10%;
}


@media screen and (max-width: 500px) {
    .Toastify__toast{
        max-width: 350px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
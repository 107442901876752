@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,800|Material+Icons');

/* Global Settings for new design */
:root {
  --elillaLilla: #3C096C;
  --elillaLightLilla: #5e17eb;
  --elillaGreen: #6da262;
  --eDarkGreen: #007762;
  --elillaGrey: #797585;
  --elillaOrange: #FFBB5E;
  --elillaRed: #940000;
  --elillaBlue: #0074AA;
  --freshRed: #D1365C;

  

  --heliotrope: #B347FE;
  --electricViolet:#973AFD;
  --cornflowerBlue :#8759FB;
  --eDarkGrey: #313131;

  --graa: #FCF7FF;
  --primary-font: 'Montserrat', sans-serif;
  --concrete: #FCF7FF;
  --yellow: #fbbd08;
}


.bg-black{
  background-color: black;
}

.bg-elilla{
  background-color: var(--elillaLilla);
}
.bg-korn{
  background-color: var(--elillaOrange);
}
.success-button{
  background-color: var(--elillaGreen) !important;
color: white !important
}

.ui.positive.button, .ui.positive.buttons .button{
  background-color: var(--elillaGreen) !important;
  color: white !important

}
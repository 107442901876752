.feed-explanation-container{
    display: flex;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
}
.feed-explanation-container-title{
    font-weight: 600;
}
/*.main-container{
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
}*/
.postItemContainer{

    height: 350px;
    margin-bottom: 30px;
}

.imageContainer img {
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imageContainer {
  position: relative;
  height: 200px;
  width: 100%;
}
.postItemImg {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  color: #4a4a4a;
}
.card-img-top {
  width: 100%;
}
.postItemImg img {
  display: block;
  width: 224px;
  height: 224px;
  border: none;
  background-color: #f5f5f4;
  border-radius: 0;
}
.postItemText {
  margin: 10px;
  color: #5e5e5e;
  font-size: 15px;
}
.postItemText h1 {
  font-size: 1.5em;
  margin-bottom: 0.75em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.post-link {
  text-decoration: none;
  color: var(--eDarkGrey);
  
}
.post-link:hover {
  cursor: pointer;
}


.post-link .post-item-container:hover{
background-color: var(  --concrete); /*TODO: hover will affect th efav button*/

}

.post-item-container:hover .postitem-label{
  background-color:var(--concrete);

}
.postitem-fav-label:hover{
  transform: scale(1.40);
}
.location-container{
  color: black !important;
}
.second-navbar{
    top: 20px;
}
.card-description{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.postitem-label{
  font-size: 1rem;
  background-color: white;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  padding: .75em 1rem;
  font-size: .85714286rem;
}

.postitem-fav-label{
  float: right;
  color: var(--graa)
}
.fav-label{
  color: var(--graa)
}
.post-item-header {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.post-item-container{
  min-height: 360px !important;
  border-radius: 16px !important;
  overflow: hidden;
}
.user-cover-img {
  height: 300px;
  width: 100%;
  margin-top: -3rem;
  background-color: rgb(168, 199, 176);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px !important;
  overflow: hidden;
}
@media screen and (max-width: 500px) {
  .user-cover-img {
    height: 200px;
    width: 100%;
    margin-top: -3rem;
  }
}
.user-profile-thumbnail {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  top: -10px;
}
.user-profile-thumbnail-placeholder {
  height: 120px;
}

.edit-profile-btn {
  text-align: right;
  width: 100%;
  cursor: pointer;
}

.copy-url-btn {
  cursor: pointer;
  margin-top: 6px !important;
  margin-right: 5px !important;
  float: right;
}

.profile-text-area{
  height: fit-content;

}
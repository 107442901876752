.messenger-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: white;
  padding: 2px 0px;

  height: 100%;


}

.conversations-list-container {
    padding: 0px;
    border-right: 1px solid rgba(34,36,38,.15);
    width: 25%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

.conversation-container {
position: relative;
overflow-y: scroll;
overflow-x: hidden;
-webkit-overflow-scrolling: touch;
background: white;
width: 75%


}

.no-conversaation-selected-container{
    width: 75%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

}

.top-conv-list-container{
    height: 60px;
    color: black;
    border-bottom: 1px solid rgba(34,36,38,.15);
    display: flex;
    padding: 7px;

}

.top-conv-list-container div{
cursor: pointer;
}
.scrollable {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  


  @media (max-width: 769px){

    .conversations-list-container {
        border-right: none;
        width: 100%;
    }
    
    .conversation-container {
    width: 100%
    
    }}

    .close-chat-btn{
        display: flex;
        justify-self: center;
        align-items: center;
    }
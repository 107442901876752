.middleSpot {
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/* background-color: var(--elillaLilla);*/
background-color: white;

  
/*background-image: url("/images/moving_woman.jpeg")*/
  background-position: center;
  background-size: cover;
  width: 100%;
  color: white;
  font-size: 5rem;
  -webkit-transition: background-image 0.5 ease-out;
  transition: background-image 0.5s ease-out;
}
.middelspot-text-container{
  position: absolute;
  z-index: 10;
  top: 120px;
  z-index: 2;

}
.fade-in-animation{
  
}
.middelspot-text-container h1{
  color: var(--elillaLilla) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 3rem !important;
}

.middelspot-text-container h2{
  color: var(--elillaLilla) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;

}

.middelspot-text-container i {
  font-size: 2.5rem;
}
.ms-pipe{
  position: absolute;
  right: -80px;
  top: 80px;
  width: 200px;
  z-index: 1;

}
.ms-drop{
  width: 9px;
  position: absolute;
  right: 49px;
  top: 155px;
  animation: dropdown 4s ease infinite ;
  z-index: 2;

}
.ms-movingfam{
  width: 500px;
  position: absolute;
  bottom: 20px;
  z-index: 2;

}
.ms-redBirds{
  position: absolute;
  top: 80px;
  right: 200px;
  width: 150px;
  z-index: 1;


}
.ms-leaves{
  position: absolute;
  bottom: 70px;
  right: 100px;
  width: 200px;
  z-index: 1;


}
.ms-ring{
  position: absolute;
  top: 270px;
  left: 50px;
  width: 300px;
  z-index: 1;

}
.ms-line{
  position: absolute;
  z-index: 0;
  bottom: 40px;
  width: 100%;
}
.ms-bucket{
  position: absolute;
  bottom: 70px;
  right: 20px;
  width: 100px;
  z-index: 3;
}
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes dropdown
{
  from {transform: translateY(0); opacity: 1;}
  to { transform: translateY(120vh); opacity: 0;}
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}
/*old styling*/
.carousel {
  height: calc(100vh - 65px);
  width: 100%;
}
.carousel-inner,
.carousel-item {
  height: 100%;
  width: 100%;
}
.carousel-image-container {

  mix-blend-mode: overlay;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;

 


}

.middelspot-filter {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-image: linear-gradient(
    135deg,
    rgba(116, 71, 199, 0.863),
    rgba(132, 102, 189, 0.1)  );
}


.middleSpot h1 {
  font-size: 6rem;
  color: white;
  margin-bottom: 0px;
}
.carousel-image-container h1 {
  font-size: 3.5rem;
  color: white;
  border-radius: 10px;
  background-color: var(--elillaLilla);
  margin-bottom: 0px;
  padding: 10px;
}

.carousel-image-container h2 {
  margin-top: 0px;
  font-size: 2rem;
  color: var(--elillaLilla);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
}

.hero-discover-container {
  font-size: 2.4rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: var(--elillaLilla);
  cursor: pointer;
  color: white;
}
.discover-icon {
  font-size: 4rem;
}
.info-callToAction {
  position: absolute;
  bottom: 160px;
  z-index: 10;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: white;
}
.carousel-image-container .hero-text-container {
  left: 40px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 200px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-image-container-A .hero-text-container {
  left: 15%;
  bottom: 200px;


}


@media screen and (min-width: 1400px) {
  .carousel-image-container {
    background-position: top;
  }
}

@media screen  and (max-width: 1024px) {
  .carousel-image-container .hero-text-container {
    left: 0;
    right: 0;   
    bottom: 100px;
    justify-content: flex-end;

  }

}
@media screen  and (max-width: 768px) {
  
  .carousel-image-container h1 {
    font-size: 1.8rem;
  }

  .carousel-image-container h2 {
    font-size: 1.6rem;
    margin-top: 0px;
    padding: 0px 5px;
    background-color: white;
    margin-top: 10px;
    border-radius: 5px;

  }
  
  .carousel-image-container .hero-text-container{
    left: 0;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 100px;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .discover-icon {
    font-size: 1.3rem;
  }

  .ms-drop{
    position: absolute;
    right: 10px;
    top: 521px;
  }

  .ms-redBirds{
    position: absolute;
    top: 270px;
    right: 200px;
    width: 150px;
    display: none;
  }
  .ms-leaves{
    position: absolute;
    bottom: 100px;
    right: 0px;
    width: 150px;
  }

  .ms-line{
    position: absolute;
    z-index: 0;
    bottom: 255px;
    width: 900px;
  }
}


@media screen  and (max-width: 568px) {

  .ms-pipe{
display: none;
  }
  .ms-drop{
display: none;
  }
  .ms-movingfam{
    width: 300px;
    position: absolute;
    bottom: 100px;
    z-index: 10;
  }
  .ms-redBirds{
    position: absolute;
    top: 200px;
    right: 200px;
    width: 150px;
  }
  .ms-leaves{
    position: absolute;
    bottom: 390px;
    right: -20px;
    width: 150px;
    z-index: 1;
  }
  .ms-ring{
    position: absolute;
    top: 370px;
    left:  200px ;
    
    width: 150px;
  }
  .ms-line{
    position: absolute;
    z-index: 0;
    bottom: 300px;
    width: 100%;
  }
}
.modal-header{
    display: block;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    background: #fff;
    margin: 0;
    padding: 1.25rem 1.5rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: rgba(0,0,0,.85);
    border-bottom: 1px solid rgba(34,36,38,.15);
    margin-bottom: 15px;
}

.category-item {
    height: 200px;
    width: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 15px;
    position: relative;
    cursor: pointer;
}

.category-item-content{
    background-color: white;
    color: var(--elillaLilla);
    position: absolute;
    padding: 4px;
    bottom: 0;
    font-size: 1.5em;
    width: 100%;
    font-weight: bold;
}

.newTask-modal-content{
}
.new-task-back-button{
    cursor: pointer;
position: relative;
top: 10px;
}
.category-dropdown{
    margin-bottom: 10px;
    
}

@media screen and (max-width: 500px) {
  .filter-menu-container {
    width: 100%;
  }
}

.custom-menu-style {
  background: white;
  box-shadow: rgba(50, 93, 88, 0.1) 15px 0px 35px 0px,
    rgba(0, 0, 0, 0.1) -5px 0px 15px 0px;
}

.reset-filters-btn {
  position: absolute;
  left: 20px;
  cursor: pointer;
}

.filter-menu-container {
  width: 350px;
}
.filter-menu-container div {
  margin: 0;
}

.drawer-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.drawer-close-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

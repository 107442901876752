
.userthumbnail-image--hori{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.userthumbnail-image--verti{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.userthumbnail-image--simple{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.userthumbnail-image--verti-large{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.menu-thumbnail {
    font-weight: 400 !important;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
    font-size: '1.14285714rem' !important;

}
.userthumbnail-image--hori.letter-icon-userthumbnail p{
    font-size: 40px ;
    color:  white !important; 

}
.userthumbnail-image--verti.letter-icon-userthumbnail p{
    font-size: 60px ;
    color:  white !important; 

}

.letter-icon-userthumbnail p{
    color: white !important;
    font-size: 100px;
}

.letter-icon-userthumbnail{
    display: flex;
    align-items: center;
    justify-content: center;
}


.lettr-icon-userTh.letter-icon-userthumbnail {
   color:  white !important; 
}

.conversation-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
 
  }
  .conversation-list-item-selected {
    background: #eeeef1;

  }
  .conversation-list-item:hover {
    cursor: pointer;
  }
  
  .conversation-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .conversation-title {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
  }
  
  .conversation-snippet {
    font-size: 14px;
    color: #888;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .conversation-is-unread{
    background-color: #d9ecff;
    
  }

  .notification-label-convlist{
    position: absolute;
    right: 27px;
  
  }
.gallery-image-container {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  font-family: "Soleil", Helvetica, Arial, sans-serif;
  color: #404040;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: zoom-in;
  position: relative;
  width: 600px;
  height: 500px;
  overflow: hidden;
  max-width: 100%;
  border-radius: 16px !important;
  overflow: hidden;
}

.image-view-container {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  font-family: "Soleil", Helvetica, Arial, sans-serif;
  color: #404040;
  -webkit-box-direction: normal;
  cursor: zoom-in;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  display: flex;
  flex-direction: row;
  transition: transform 200ms ease-out 0s;
}

.gallery-image {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  font-family: "Soleil", Helvetica, Arial, sans-serif;
  color: #404040;
  -webkit-box-direction: normal;
  cursor: zoom-in;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  width: 600px;
  height: 600px;
  background-color: rgb(255, 255, 255);
  background-size: cover;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: center;

}

.gallery-thumbs{
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  font-family: "Soleil", Helvetica, Arial, sans-serif;
  color: #404040;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  max-width: calc(100% + 20px);
  margin: 10px -10px;
  display: flex;
}

.gallery-thumbs__thumb {
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: none;
  font-family: inherit;
  padding: 0;
  border: none;
  outline: none;
  margin: 10px;
  width: 62px;
  height: 62px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s ease;
    border: 1px solid slategrey;
    background-size: cover;

    border-radius: 16px !important;
    overflow: hidden;
  
}
.gallery-thumbs__thumb-selected{
  opacity: 1;

}
.gallery-thumbs__thumb img{
  width: 100%;
  height: 100%;
}
.slide-buttons {
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  font-family: "Soleil", Helvetica, Arial, sans-serif;
  color: #404040;
  -webkit-box-direction: normal;
  cursor: zoom-in;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
}
.slide-buttons .left-slide-button,
.slide-buttons .right-slide-button {
  position: absolute;
  top: 50%;
  cursor: pointer;
  border-radius: 50%;
  background-color: #404040;
  opacity: 0.6;
  height: 35px;
  width: 35px;  
  display: flex;
    justify-content: center;
    align-items: center;
}

.slide-buttons .right-slide-button {
  right: 0px;

}
.slide-buttons .right-slide-button i,
.slide-buttons .left-slide-button i
{
  opacity: 1 !important;
  color: white;
}
.empty-gallery-placeholder{
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0;
    color:white;
    width: 600px;
    height: 500px;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px !important;
    overflow: hidden;

}

.gallery-view-button{

    position: absolute;
    left: 0; 
    right: 0; 
    bottom: 5%;
    margin-right: auto; 
    margin-left: auto; 
    width: fit-content; /* Need a specific value to work */
    background-color: #404040;
    opacity: 0.6;
    z-index: 1;
    cursor: pointer;
    color: white;
    padding: 5px;
    border-radius: 6%;
}
.gallery-view-button i,
.gallery-view-button span
{
opacity: 1 !important;
}

@media screen and (min-width: 500px) {
    .gallery-view-button
    {
      display: none;
    }
  }
  
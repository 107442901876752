.facebookShareBtn{
    background-color: #3b5998;
    color: #fff;
    text-shadow: none;
    background-image: none;
    -webkit-box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    font-size: 1rem;

    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;

    padding: 3px 12px;
    text-transform: none;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
}



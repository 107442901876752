.App {
background-color: white;;
padding-left: 15px;
padding-right: 15px;
min-height: 100vh;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

input:disabled{
  background-color: #e9ecef;
  opacity: 1;
}

.modal-container{
  z-index: 1300px;
}

.pointer {
  cursor: pointer !important;
}
.green-btn-style{
  background-color: var(--green) !important;
  color: white !important;
}
.bold{
  font-weight: bold;
}

.app-container{
 /* padding-top: 30px;*/
  min-height: 80vh;
      padding-top: 30px !important;
}
/*
.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.modal,
.ui.active.modal {
  margin: 0 auto!important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
}


*/
.forgot-pass-container{
    padding: 10px 10px;

}
.forgot-back-btn{
   width: 100%;
   margin-top:20px;
   text-align: center;
   display: flex;
   justify-content: center;
}
.forgot-back-btn p {
    cursor: pointer;
    padding: 10px;
}
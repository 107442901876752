.topbar{
  width: 100%;
  height: 60px;
  border-bottom: solid 1px grey;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 997;
  background:white;
}

.mobile-topbar{
  width: 100%;
  height: 60px;
  border-bottom: solid 1px grey;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50 !important;
  background:white;
}


.topbar-connected{
  border: none;
}

.container-connected{
  width: 100%
}

.subbar-connected{
  position: fixed;
  top: 75px;
  width: 100%;
  z-index: 99;
  background: white !important;
  border-bottom: 1px solid rgba(34,36,38,.15) !important;
  box-shadow: 0 2px 1px 0 rgba(34,36,38,.15);
  -webkit-box-shadow: 0 2px 1px 0 rgba(34,36,38,.15);

}

.subbar-connected-m{
  top: 74px;


}
.fliter-btn{
  
  position: absolute;
  left: 16px;
  bottom: 10px;

}
.fliter-btn-connected{
  position: fixed;
  z-index: 100;
  top: 90px;
  left: 10%;

}
.fliter-btn-connected-m{
  bottom: 10px !important;
  left: 25px;

}
.subbar{
  border-bottom: solid 1px grey;
  display: flex;
  justify-content: center;
}

.subbar-buttons{
  width: 100px;
  height: 50px;
  font-weight: bold;
  color: #bbb;
  text-align: center;
  line-height: 50px;
  text-transform: uppercase;
  font-family: 'Lato','Helvetica Neue','Arial','Helvetica','sans-serif'
}
.subbar-buttons:hover{
  color: black;
  cursor: pointer;
}

.subbar-buttons-selected{
  color: black;
}



.bar{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-bottom: 65px;
}

.hide-top-bar{
  top: -60px !important;
  transition: top 0.3s;

  
}
.push-sub-bar{
top: 0px !important;
transition: top 0.3s;

}
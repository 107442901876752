
.skewed-image{
 background: url(http://placekitten.com/301/301);
  position: absolute;
  top: -30px;
  left: -30px;
  right: -30px;
  bottom: -30px;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}
.labelize-input textarea, .labelize-input input{
  border: none !important;
  padding: 0 !important;
  }
  
  .labelize-input textarea,{
    height: -webkit-fill-available;
  }
  .labelize-input input{
    border: none!important;
    margin: calc(2rem - .14285714em) 0 1rem!important;
    padding: 0 0!important;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif!important;
    font-weight: 700!important;
    line-height: 1.28571429em!important;
    text-transform: none!important;
    color: rgba(0,0,0,.87) !important;
    font-size: 1.71428571rem!important;
    width: 100%!important;
  }
  .service-cover-image{
    width: 100%;
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
      border-radius: 16px !important;
    overflow: hidden;
  }
  .skewed-element::before{
    z-index: 0;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -45px;
    display: block;
    width: 100px;
    background-color:white;
    -webkit-transform: skew(7deg);
    -ms-transform: skew(7deg);
    transform: skew(7deg);
  }
  .skewed-element{
    position: relative;

  }
  .see-full-profile{


    align-items: center;
    justify-content: center;
    display: flex;
  }

  .see-full-profile p{
    cursor: pointer;
    text-decoration: underline;
    color: black;
  }/*
  @media (max-width: 768px) {

  .service-detail-row-container{
          flex-direction: row-reverse;
    }

  }
*/
.navbarContainer {
  padding: 1em;
  border-bottom: 1px solid gainsboro;
  background-color: #fafafa;
  z-index: 999;
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
}
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-brand {
  color: black;
}
.nav-item {
  border: 1px solid gainsboro;
  margin-left: 3px;
  text-align: right;
  padding: 3px;
}
.nav-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.modalLoadingStyle {
  background-color: transparent;
  box-shadow: none;
}
.closeBtnClassname{
left: 14px;
}

.navbar-placeholder{
  height: 65px;
/*  margin-bottom: 3rem;*/
}


.burger-menu-icon{
  margin-right: -65px !important;
  cursor: pointer;
}
.notification-color{
margin-left: 3px !important;
}
.topbar-logo-image{
  width: 135px !important;
  cursor: pointer;
  margin: 3px 0px;
}

.mobile-topbar-logo{
  width: 120px;
  cursor: pointer;
  margin: 3px 0px;
  height: auto;
}
@media screen  and (max-width: 768px) {
  .mobile-topbar-logo{
  margin-left: 30px;
  }
}
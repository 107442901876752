.uploaded-image-gallery img {
  object-fit: cover;
  height: 100px;
  width: 100px;

}
.uploaded-image-gallery {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
align-self: center;
}
.uploaded-image-item .delete-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  left: -5px;
  cursor: pointer;
}
.uploaded-image-item {
  position: relative;
  background-color: grey;
  margin: 0px 3px;
  height: 100px;
  width: 100px;
}
.add-image-btn img{
width: 50px;
height: 50px;
}

.add-image-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    width: 100px;
    cursor: pointer;
}

.pulse {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #00CCEB;
  cursor: pointer;
  box-shadow: 0 0 0 #00CCEB;
  animation: pulse 2s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}


  .compose {
    padding: 10px;
    display: flex;
    align-items: center;
    background: white;
    border-top: 1px solid #eeeef1;
    position: fixed;
    width: inherit;
    bottom: 0px;
  }
  .modal-style{
    position: unset;
    background-color: unset !important;

  }
  
  @supports (backdrop-filter: blur(20px)) {
    .compose {
      border: none;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(20px);
    }
  }
  
  .compose-form {
display: flex;
width: 100%;
align-items:center;
  }
  

  
  .compose .toolbar-button {
    color: #bbbbbf;
    margin-left: 15px;
  }
  
  .compose .toolbar-button:hover {
    color: #99999c;
  }

.steps-container {
margin-bottom: 50px;
}
.steps-container .steps-item img {
  width: 40px;
}

.steps-container .steps-item {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*padding: 31px;*/
}

.step-card-header {
  display: flex;
  justify-content:start;
  align-items: center;
  border-bottom: 0.6px solid var(--elillaLilla);
  margin: auto;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-weight: 600;
}
.step-card-header img {
  margin-right: 5px;
}

@media only screen and (max-width: 767px) {
  .steps-container .steps-item {
    margin-bottom: 20px;
  }
}

.step-label {
  /*left: 17px !important;
  /* right: 0; */
  /*position: absolute !important;
  z-index: 2;
  top: 40px !important;*/
  background-color: var(--elillaLilla) !important;
  color: white !important;
  margin-right: 10px !important;

}
.steps-modal-btn-container{
    border-top: 0.6px solid black;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-top: 25px;
}
.steps-card-style{
    min-height: 200px !important;
}


.labelize-input textarea, .labelize-input input{
border: none !important;
padding: 0 !important;
}

.labelize-input textarea,{
  height: -webkit-fill-available;
}
.labelize-input input{
  border: none!important;
  margin: calc(2rem - .14285714em) 0 1rem!important;
  padding: 0 0!important;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif!important;
  font-weight: 700!important;
  line-height: 1.28571429em!important;
  text-transform: none!important;
  color: rgba(0,0,0,.87) !important;
  font-size: 1.71428571rem!important;
  width: 100%!important;
}
.task-detail-container p{
color: rgba(0,0,0,.87) !important;

}

.icon-display-container .icon {
margin: 0 3px;  
cursor: pointer;
}

.icon-display-container .icon:hover{
  transform: scale(1.2);
  
}
.icon-display-container{

}

.task-detail-image-galler{
  display: flex;
  justify-content: center;
}

.footer-container {
  padding: 50px 10px;
  background-color: var(--elillaLilla);
  margin-top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-container div {
  display: flex;
  justify-content: center;
}

.footer-col {
    align-items: center;
    flex-direction: column;
justify-content: end !important;
  
}
.footer-col a ,.footer-col a, .footer-col span{
    margin-bottom: 15px;
    color: white
} 
.footer-container-placeholder{
    padding: 50px 10px;
    margin-top: 40px;
}

.footer-hori-aligned-items{
    display: flex;
    align-items: center;
    color: white;
}
.footer-social-link{
  justify-content: center;
  display: flex;
  align-items: center;
}
.footer-social-link p{ 
margin-left: 3px;
}
.footer-icon {
  margin: 0 !important;
}
.footer-social-container{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media screen and (max-width: 500px) {
    .footer-container {
        padding-bottom: 70px;

      }
      .footer-container-placeholder{
        padding-bottom: 70px;

    }

      .footer-col{

        margin-bottom: 20px;

      }
      .footer-social-container{
        display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
      }
  

    }
    .footer-social-link p{
      margin-left: 10px;
    }
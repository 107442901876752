.feedback-container {
    bottom: 0;
    right: 10px;
    position: fixed;
    z-index: 999;
    padding: 9px 20px;
    border-radius: 4px;
    color: white !important;
    font-size: 1rem;
    line-height: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    border: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    font-size: 1rem;
    background-color:var(--yellow) !important;
    
}
.Button__feedback span:before {
}

@media only screen and (max-width: 500px) { 
    .Button__feedback span:before {
        display: none;
    }

    .Button__feedback span:after {
        display: inline-flex;
        font-size: 0.8rem;
        content: 'FEEDBACK';
    }
    .feedback-container {
        bottom: 90px;
        right: 3px;
        position: fixed;
        z-index: 999;
    }
}

